import MicroModal from 'micromodal';

const append = (selector, content) => {
  $(selector).append(content);
};

const html = (selector, content) => {
  $(selector).html(content);
};

const modal = (content) => {
  document.getElementById('modal_dummy_content').innerHTML = content;
  MicroModal.show('modal_dummy', {
    onClose: modal => modal.querySelector('.micromodal__content').innerHTML = '',
  });
};

const replace = (selector, content) => {
  const $nodes = $(selector);
  $(content).insertAfter($nodes);
  $nodes.hide();
  setTimeout(() => { $nodes.remove(); }, 50);
};

const visit = (url) => {
  window.location = url;
};

export default { append, html, modal, replace, visit };
