import "~/postcss/index.css";
import 'what-input';
import 'rails-plus/es6/bundles/modernizr';

import Flips          from 'rails-plus/es6/modules/Flips';

import $               from 'jquery';
import Rails           from '@rails/ujs';
import Turbolinks      from 'turbolinks';
import { Application } from '@hotwired/stimulus';
import { registerControllers } from "stimulus-vite-helpers";
const controllers = import.meta.glob("~/controllers/*_controller.js", { eager: true });

import AutosizedTextareas         from '~/modules/AutosizedTextareas';
import FlashItems                 from '~/modules/FlashItems';
import FontAwesome                from '~/modules/FontAwesome';
import MicroModals                from '~/modules/MicroModals';
import PageEvent                  from '~/modules/PageEvent';
import SmoothScrollLinks          from '~/modules/SmoothScrollLinks';
import Tooltips                   from '~/modules/Tooltips';
import ujs                        from '~/modules/ujs';

window.$ = $;
window.jQuery = $;
window.Rails = Rails;
window.PageEvent = PageEvent;
window.ujs = ujs;

AutosizedTextareas.start();
FlashItems.start();
Flips.start();
MicroModals.start();
Rails.start();
Tooltips.start();
Turbolinks.start();

if (!('scrollBehavior' in document.documentElement.style)) { SmoothScrollLinks.start(); }

PageEvent.delegate('turbolinks:load', 'page:load');

const application = Application.start();
registerControllers(application, controllers);

if (document.readyState !== 'loading') {
  const event = document.createEvent('Event');
  event.initEvent('DOMContentLoaded', true, true);
  window.document.dispatchEvent(event);
}
