import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const url = this.data.get('url');
    const xhr = new XMLHttpRequest();
    const videoNode = this.element;

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function(e) {
      if (this.status == 200) {
        const myBlob = this.response;
        const vid = (window.webkitURL || window.URL).createObjectURL(myBlob);
        videoNode.src = vid;
        videoNode.play();
      }
    }

    xhr.send();
  }
}
